import * as React from "react"

import Layout from '../components/layout'
import Rules from '../components/rules'

// Import from a CSS file in your src
import "../styles/lcars-classic.min.css"


// document.addEventListener("touchstart", function() { console.log('touched')},false);

// $(window).scroll(function() {
//     var height = $(window).scrollTop();
//     if (height > 100) {
//         $('.scroll-top a').fadeIn();
//     } else {
//         $('.scroll-top a').fadeOut();
//     }
// });

// $(document).ready(function() {
//     $("#scroll-top").click(function(event) {
//         event.preventDefault();
//         $("html, body").animate({ scrollTop: 0 }, "slow");
//         return false;
//     });

// });


export default function BeerTrek () {
  return (
  <Layout>
    <div class="lcars-text-bar">
      <span>Beer Trek - NOT FOUND</span>
    </div>
    <h1>Live long and prosper 🖖</h1>
  </Layout>
)}
